import * as Chakra from "@chakra-ui/react";
import { responsivePixel } from "../responsive-px";
import { HeadingText } from "../text/heading-text";
import { HiCheckCircle } from "react-icons/hi";
import { BodyText } from "../text/body-text";
import DarkButton from "../dark-button";
import { UIState } from "../ui-state";
import React from "react";

interface Props {
    title: string,
    image: string,
    bullets: string[]
}


export const ProductCard = ({ title, subtitle, image, bullets }: Props) => {

    return (
        <Chakra.GridItem colSpan={1} boxShadow="0 15px 45px #756c6c36" borderRadius="25px">
            <Chakra.Flex gridGap="30px" gridTemplateColumns={{base: "repeat(1, minmax(0, 1fr))", lg: "repeat(1, minmax(0, 1fr))", xl: "none"}} height="100%" display={{base: "grid", xl: "flex"}} flexDirection={{xl: "column"}}>
                {/*<Chakra.AspectRatio ratio={1} borderRadius="25px" overflow="hidden" boxShadow="0 15px 45px #756c6c36" >
                    <Chakra.Image className="_in_l" transition="0.4s" opacity={1} position="relative" height="100%" src={image} width="100%" objectFit="cover"/>
                </Chakra.AspectRatio>*/}
                <Chakra.Flex p={responsivePixel(40)} flexDirection="column" justify="space-between" height="100%">
                    <Chakra.Flex flexDirection="column" justifyContent="center" alignItems="flex-start" >
                        <HeadingText scale={0.6} textAlign="left" pb={responsivePixel(15)}>{title}</HeadingText>
                        <Chakra.Divider/>
                        <Chakra.List spacing="4" mb="8" mx="auto" pt={responsivePixel(15)} width="100%">
                            {bullets.map((bullet, i) => (
                                <Chakra.ListItem key={i} display="flex">
                                    <Chakra.ListIcon key={i} fontSize="xl" as={HiCheckCircle} marginEnd={2} color="#CB4848" />
                                    <BodyText scale={0.75}>{bullet}</BodyText>
                                </Chakra.ListItem>
                            ))}
                        </Chakra.List>
                    </Chakra.Flex>
                    <DarkButton onClick={UIState.onOpenSignupModal}>Enquire</DarkButton>
                </Chakra.Flex>
            </Chakra.Flex>
        </Chakra.GridItem>
    )
}
