import React from 'react'
import * as Chakra from '@chakra-ui/react'
import AuthorSlider from '../components/author-slider'
import Seo from '../components/seo'
import DarkButton from '../components/dark-button'
import {BodyText} from "../components/text/body-text";
import {HeadingText} from "../components/text/heading-text";
import { Section } from '../components/landing/section'
import {responsivePixel} from "../components/responsive-px";
import { InlineCTA } from '../components/landing/inline-cta'
import QuotationLeftIcon from "../components/quotation-left-icon";
import QuotationRightIcon from "../components/quotation-right-icon";
import {Courses} from "../components/landing/courses";
import {UIState} from "../components/ui-state";
import { ProductCard } from "../components/landing/product-card";
import ShareImage from "../images/og-image.jpg"

const Index = ({location}) => {


    let pageData = UIState.useGlobalState(gs => gs.pageData);


    return (
        <Chakra.Box flex="1">
            <Seo
                shareImage={ShareImage}
                location={location}
                title="Penelope Institute"
            />
            <Chakra.Box w="100%" overflowX="hidden" bgSize="cover" position="relative">
                <Chakra.VStack maxW="1520px" m="auto" w="100%">
                    <Chakra.Box as="section" w="100%">
                        <Chakra.Box mx="auto" px={{base: '6', md: '8',}}>
                            <Chakra.Flex flexDirection={{base: "column", xl: "row"}}>
                                <Chakra.Flex
                                    mr={{base: 0, xl: '66px'}}
                                    flexBasis="50%"
                                    flexDir="column"
                                    alignItems={{
                                        base: 'center',
                                        xl: 'flex-start'
                                    }}
                                    m="auto"
                                    zIndex="1"
                                    py={{base: "5vh", md: "10vh"}}
                                >
                                    <Chakra.Box textAlign={{base: "center", md: "left"}} pb="25px" marginRight={responsivePixel(8)} pt={{base: '12', md: '24',}}>
                                        <HeadingText scale={1.6} as="span">{pageData.atfTitle}</HeadingText>
                                    </Chakra.Box>
                                    <BodyText
                                        mb={{base: '35px', xl: '64px'}}
                                        color="#000000"
                                        textAlign={{
                                            base: 'center',
                                            xl: 'left'
                                        }}
                                        maxW="480px"
                                        scale={1.2}
                                    >
                                        {pageData.atfBody}
                                    </BodyText>
                                    <InlineCTA/>

                                </Chakra.Flex>
                                <Chakra.Flex
                                    mt={{base: '53px', xl: 0}}
                                    justify="center"
                                    flexBasis="50%"
                                />
                            </Chakra.Flex>
                        </Chakra.Box>
                    </Chakra.Box>
                </Chakra.VStack>
                <Chakra.Box position="absolute" right="0" top="0" width="66%" height="100%" css={{"&:after": { content: '""', position: "absolute", width: "100%", height: "100%", background: "linear-gradient(90deg, #ffff, #fff0)", top: "0" }}}>
                    <Chakra.Image h="100%" objectFit="cover" opacity={{base: "0.4", xl: "1"}} src={pageData.atfImage} alt="" alignSelf="center" w="100%"/>
                </Chakra.Box>
                <Chakra.Divider/>
            </Chakra.Box>


            <Section bg="#fdfdfd">
                <HeadingText pb={{base: "30px", lg: "50px"}} scale={1.5} textAlign="left">Welcome</HeadingText>
                <Chakra.Stack direction={{base: 'column', md: 'row',}} spacing={{base: '12', xl: '20',}}>

                    <Chakra.Box maxW={{lg: "450px"}} flex="1" flexShrink={0}>
                        <Chakra.Img w="full" h="full" maxHeight="600px" objectFit="cover" borderRadius="17px" src={pageData.welcomePhoto}/>
                        <Chakra.Flex pt={responsivePixel(30)} alignItems="center" justifyContent="center" width="100%" flexDirection="column">
                            <HeadingText textAlign="center" scale={0.8}>{pageData.welcomeName}</HeadingText>
                            <BodyText textAlign="center" pt="12px" scale={1}>{pageData.welcomeCredentials}</BodyText>
                        </Chakra.Flex>
                    </Chakra.Box>
                    <Chakra.Box flex="1">
                        <Chakra.Box py={responsivePixel(18)}>
                        <Chakra.Box top="0">
                            <QuotationLeftIcon />
                        </Chakra.Box>
                        <BodyText scale={1} align={{base: 'center', md: 'start',}} py={{base: "10px", md: "20px"}} textAlign="left">
                            {pageData.welcomeBody}
                        </BodyText>
                        <Chakra.Flex width="100%" justifyContent="flex-end">
                            <QuotationRightIcon />
                        </Chakra.Flex>
                        </Chakra.Box>
                        <Chakra.Flex width="100%" justifyContent="flex-end">
                            <InlineCTA/>
                        </Chakra.Flex>
                        {/* <Chakra.Stack direction={{base: 'column', md: 'row',}} spacing="5" mt="6" align="center" textAlign={{base: 'center', md: 'start'}}>
                            </Chakra.Stack> */}
                    </Chakra.Box>
                </Chakra.Stack>
            </Section>
            <Chakra.Divider/>
            <Section maxW="1480px" >
                <Chakra.Stack width="100%" maxW="1520px" m="auto" direction={{base: "column", lg: "row"}} spacing={responsivePixel(40)}>
                    <HeadingText w="auto" textAlign="left" pb={{base: "30px", lg: "50px"}} scale={1.5}>{pageData.fellowsTitle}</HeadingText>
                    <BodyText w="auto" textAlign="left">
                        {pageData.fellowsBody}
                    </BodyText>
                </Chakra.Stack>

                    <Chakra.Flex bg="transparent" position="relative" borderRadius="80px 0 80px 0" w="100%" mt="14vh">
                        <Chakra.Box position="relative" m="auto" marginTop="-14vh" display="inline-flex" maxWidth="100%">
                            <Chakra.Flex py={responsivePixel(50)} alignItems="center" w="100%" maxWidth="1520px" m="auto">
                                <AuthorSlider authors={pageData.fellowsSlides} arrows/>
                            </Chakra.Flex>
                        </Chakra.Box>
                    </Chakra.Flex>
                <InlineCTA />
            </Section>

            <Chakra.Divider/>
            <Section maxW="1480px" >
                <Chakra.Stack width="100%" maxW="1520px" m="auto" direction={{base: "column", lg: "row"}} spacing={responsivePixel(40)}>
                    <HeadingText w="auto" textAlign="left" pb={{base: "30px", lg: "50px"}} scale={1.5}>{pageData.coursesTitle}</HeadingText>
                    <BodyText w="auto" textAlign="left">
                        {pageData.coursesBody}
                    </BodyText>
                </Chakra.Stack>
                <Courses />
                <InlineCTA />
            </Section>
            <Chakra.Divider/>
            <Section>
                <HeadingText w="auto" textAlign="left" pb={{base: "30px", lg: "50px"}} scale={1.5}>{pageData.whatWeOfferTitle}</HeadingText>
                <Chakra.SimpleGrid spacing={{base: "30px", lg: "60px"}}>
                    {/*pageData.whatWeOfferFeatures.map((feature, i) => (
                        <WhatWeOfferFeature key={i} image={feature.image} title={feature.title} body={feature.body} />
                    ))*/}
                    <Chakra.Grid width="100%" pt={responsivePixel(60)} templateColumns={{base: "repeat(1, 1fr)", lg: "repeat(1, 1fr)", xl: "repeat(3, 1fr)"}} gap="30px">
                        {pageData.products.map((product, i) => (
                            <ProductCard key={i} title={product.title} bullets={product.bullets} />
                        ))}
                    </Chakra.Grid>
                </Chakra.SimpleGrid>
            </Section>

            <Chakra.Divider/>
            {/*<Section maxW="1480px">
                <HeadingText pb={{base: "30px", lg: "50px"}} textAlign="left" scale={1.5}>{pageData.testimonialsTitle}</HeadingText>
                <Chakra.Flex bg="transparent" position="relative" borderRadius="80px 0 80px 0" w="100%" mt="14vh">
                    <Chakra.Box width="100%" position="relative" m="auto" marginTop="-14vh" display="inline-flex" maxWidth="100%">
                        <Chakra.Flex py={responsivePixel(50)} alignItems="center" w="100%" maxWidth="1520px" m="auto">
                            <HearHero data={pageData.testimonials}/>
                        </Chakra.Flex>
                    </Chakra.Box>
                </Chakra.Flex>
            </Section>*/}

            <Chakra.Box bg="#21375C">
                <Chakra.Flex maxWidth="1520px" direction="column" w="100%" m="auto" py={responsivePixel(70)} alignItems="center"
                             justifyContent="center">
                    <Chakra.Box pb="18px" color="#fff" transform={{base: "scale(0.5)", sm: "scale(0.6)", md: "scale(0.7)", lg: "scale(0.8)", xl: "scale(1)"}}>
                        <Chakra.Text fontSize="53px" lineHeight="40px" textTransform="lowercase"
                                     fontFamily="Handsome Pro" as="span" pl="8px" pr="16px"
                                    _before={{
                                        content: '"Beyond"',
                                        position: {base: 'relative', lg: 'absolute'},
                                        fontSize: '24px',
                                        textTransform: 'uppercase',
                                        letterSpacing: '8px',
                                        fontFamily: 'body-alt',
                                        transform: {lg: 'translateX(-108%)'},
                                        paddingRight: {base: '6px', lg: '0'},
                                        bottom: { lg: '0' },
                                        lineHeight: { lg: '75px' }
                                    }}
                                     _after={{
                                         content: '"Boundaries"',
                                         position: {base: 'relative', lg: 'absolute'},
                                         fontSize: '24px',
                                         textTransform: 'uppercase',
                                         letterSpacing: '8px',
                                         fontFamily: 'body-alt',
                                         transform: {lg: 'translateX(8%)'},
                                         paddingLeft: {base: '12px', lg: '0'},
                                         bottom: { lg: '0' },
                                         lineHeight: { lg: '75px' }
                                     }}

                        >All</Chakra.Text>
                    </Chakra.Box>
                    <Chakra.Text pb="28px" color="#fff" fontSize="12px" textStyle="rich">
                        Copyright &copy; 2021 Penelope Institute
                    </Chakra.Text>
                    <DarkButton onClick={UIState.onOpenSignupModal}>
                        Get Started
                    </DarkButton>
                </Chakra.Flex>
            </Chakra.Box>
        </Chakra.Box>
    )
}

export default Index
