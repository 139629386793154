import React from "react";
import * as Chakra from '@chakra-ui/react'

function ArrowLeftIcon(props) {
  return (
    <Chakra.Box as="svg" {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="44px"
      height="44px"
      fill="none"
      viewBox="0 0 44 44"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#000"
          d="M22 44A22 22 0 1122 .001 22 22 0 0122 44zm9.625-20.625a1.375 1.375 0 000-2.75h-15.93l5.904-5.902a1.377 1.377 0 00-1.948-1.947l-8.25 8.25a1.376 1.376 0 000 1.948l8.25 8.25a1.377 1.377 0 001.948-1.948l-5.905-5.901h15.931z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H44V44H0z"
            transform="rotate(90 22 22)"
          ></path>
        </clipPath>
      </defs>
    </Chakra.Box>
  );
}

export default ArrowLeftIcon;
