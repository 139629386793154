import React from 'react'
import { BodyText } from "./text/body-text";
import { responsivePixel } from "./responsive-px";

function QuotationRightIcon() {

    return (
        <BodyText textAlign="right" scale={6} color="#CB4848" lineHeight="0px" paddingTop={responsivePixel(55)} marginRight="-6px">
            ”
        </BodyText>
    )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="33"
      fill="none"
      viewBox="0 0 40 33"
    >
      <path
        fill="#CB4848"
        d="M22.718 33c7.088-.498 17.276-1.63 17.282-15.522V0H24.183v18.7h5.252c.333 4.993-3.776 6.283-8.244 7.272L22.718 33zM1.527 33c7.088-.498 17.277-1.63 17.282-15.522V0H2.992v18.7h5.252c.333 4.993-3.776 6.283-8.244 7.272L1.527 33z"
      ></path>
    </svg>
  )
}

export default QuotationRightIcon
