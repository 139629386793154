import React from 'react'
import { BodyText } from "./text/body-text";
import { responsivePixel } from "./responsive-px";

function QuotationLeftIcon() {

    return (
        <BodyText scale={6} color="#CB4848" lineHeight="0px" paddingTop={responsivePixel(55)} marginLeft="-6px">
            “
        </BodyText>
    )
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="33"
      fill="none"
      viewBox="0 0 40 33"
    >
      <path
        fill="#CB4848"
        d="M17.282 0C10.194.498.006 1.63 0 15.522V33h15.817V14.3h-5.252c-.333-4.993 3.776-6.283 8.244-7.272L17.282 0zm21.191 0c-7.088.498-17.277 1.63-17.282 15.522V33h15.817V14.3h-5.252C31.423 9.307 35.532 8.017 40 7.028L38.473 0z"
      ></path>
    </svg>
  )
}

export default QuotationLeftIcon
