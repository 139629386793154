import * as Chakra from "@chakra-ui/react";
import {responsivePixel} from "../responsive-px";
import { CourseCard, CourseCardProps } from "./course-card";
import React, {useState} from "react";
import {BodyText} from "../text/body-text";
import Fuse from 'fuse.js';
import DarkButton from "../dark-button";
import { UIState } from "../ui-state";



export function Courses(props) {

    let pageData = UIState.useGlobalState(gs => gs.pageData);
    let data = pageData.courses;


    const [query, setQuery] = useState<string>("");
    const [showAll, setShowAll] = useState<boolean>(false);

    const fuse = new Fuse(data, {
        isCaseSensitive: false,
        includeScore: true,
        shouldSort: true,
        includeMatches: true,
        findAllMatches: false,
        minMatchCharLength: 1,
        location: 0,
        threshold: 0.3,
        distance: 100,
        useExtendedSearch: false,
        ignoreLocation: false,
        ignoreFieldNorm: false,
        keys: ['title']
    });

    const results = query ? fuse.search(query).map(returnable => returnable.item) : data;

    return (
        <>
            <Chakra.Flex pt={responsivePixel(40)} pb={responsivePixel(5)}>
                <Chakra.Flex borderRadius="20px 0 20px 0" m="auto" bg={{md: ""}} d="inline-block" px={responsivePixel(10)} py={responsivePixel(6)}>
                    <Chakra.Stack pb={responsivePixel(20)} flexDirection={{base: "column", md: "row"}} alignItems="center" justifyContent="center" spacing={{base: "20px", md: "0"}}>
                        <BodyText width="auto" scale={0.8} px={responsivePixel(20)}>Need the perfect course?</BodyText>
                        <Chakra.Input bg="white" m="auto" value={query} onChange={(e)=> { setQuery(e.target.value); setShowAll(false) }} maxWidth={{base: "100%", md: "80%", lg: "60%", xl: "40%"}} placeholder="Search..."/>
                    </Chakra.Stack>
                    <Chakra.Divider/>
                    <Chakra.Stack pt={responsivePixel(20)} flexDirection={{base: "row"}} alignItems="center" justifyContent="center" spacing={{base: "0", md: "0"}}>
                        <BodyText width="auto" scale={0.8} px={responsivePixel(20)}>Show All Courses</BodyText> <Chakra.Switch isChecked={showAll} onChange={()=>{ setShowAll(!showAll); query && setQuery("")}} />
                    </Chakra.Stack>

                </Chakra.Flex>
            </Chakra.Flex>
            <Chakra.Flex bg="transparent" position="relative" borderRadius="80px 0 80px 0" w="100%" mt="14vh" {...props}>
                <Chakra.Box position="relative" m="auto" marginTop="-14vh" display="inline-flex" width="100%">
                    <Chakra.SimpleGrid maxWidth="1520px" m="auto" columns={{base: 1, lg: 2, xl: 3}} py={responsivePixel(50)} spacing="30px" width="100%">
                        {results.slice().splice(0, (showAll || query) ? results.length : 3).map((item, i) => (
                            <CourseCard key={i} {...item} />
                        ))}
                    </Chakra.SimpleGrid>
                </Chakra.Box>
            </Chakra.Flex>
        </>
    )
}
