import React from 'react'
import * as Chakra from '@chakra-ui/react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import {HeadingText} from "./text/heading-text";
import {BodyText} from "./text/body-text";
import {responsivePixel} from './responsive-px';
import DarkButton from "./dark-button";
import {InlineCTA} from "./landing/inline-cta";
import {SubtitleText} from "./text/subtitle-text";
import QuotationLeftIcon from "./quotation-left-icon";
import QuotationRightIcon from "./quotation-right-icon";
import {UIState} from "./ui-state";


interface Props extends Chakra.BoxProps {
  image: any,
  title: string,
    subtitle: string,
  summary?: string
    expandedParagraph?: string,
    objectPosition?: string
}
const Author = ({image, title, subtitle, summary, expandedParagraph, objectPosition, ...props}: Props) => {

    const { isOpen, onOpen, onClose } = Chakra.useDisclosure()

  return (
      <>
        <Chakra.Flex cursor="pointer" onClick={onOpen} boxShadow="0 15px 45px #756c6c36" _hover={{"._in_l": {transform: "scale(1.05)"}, ".in_l_f": { "span": { marginRight: "8px"}, "&:after": { opacity: 1, right: "-15px" }}}} w="100%" position="relative" flexDir="column" borderRadius="14px" bg="#fff" className="keen-slider__slide"{...props}>
          <Chakra.Box height="36%" maxHeight="350px" overflow="hidden" borderRadius="14px">
            <Chakra.Image
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                  objectPosition: objectPosition
              }}
              className="_in_l"
              src={image}
              transition="0.4s"
            />
          </Chakra.Box>
            <Chakra.Flex height="70%" flexDirection="column" justifyContent="space-between" pb={responsivePixel(8)}>
            <Chakra.Box>
            <Chakra.Box zIndex="1" position="relative" px={responsivePixel(23)} pt={responsivePixel(24)}>
                <Chakra.Flex display="inline-block" position="relative"
                             alignItems="center"
                             justifyContent="center"
                             className="in_l_f"
                             _after={{
                                 content: '"\\2192"',
                                 position: 'absolute',
                                 opacity: 0,
                                 right: "-10px",
                                 transition: "0.5s",
                                 top: "3px",
                                 height: "100%"
                             }}
                             css={{
                                 "span": {
                                     transition: "0.5s"
                                 }
                             }}>
                    <HeadingText py={responsivePixel(16)} textAlign="center" color="#000" scale={0.5} as="span">{title}</HeadingText>
                </Chakra.Flex>
            </Chakra.Box>
            <Chakra.Box zIndex="1" position="relative" px={responsivePixel(23)} py={responsivePixel(8)}>
                <Chakra.Flex display="inline-block" position="relative" alignItems="center" justifyContent="center">
                    <SubtitleText scale={0.7}>{subtitle}</SubtitleText>
                </Chakra.Flex>
            </Chakra.Box>
              <Chakra.Box p="12px 23px 30px 23px" position="relative">
                  <Chakra.Box top="0">
                      <QuotationLeftIcon />
                  </Chakra.Box>
                  <BodyText color="#000" py={responsivePixel(15)} scale={0.85}>{summary}</BodyText>
                  <Chakra.Flex width="100%" justifyContent="flex-end">
                      <QuotationRightIcon />
                  </Chakra.Flex>
              </Chakra.Box>
                </Chakra.Box>
            <Chakra.Flex justifyContent="center" alignItems="center" pb={responsivePixel(25)}>
                <DarkButton noArrow>Biography</DarkButton>
            </Chakra.Flex>
            </Chakra.Flex>
        </Chakra.Flex>
          <Chakra.Modal isOpen={isOpen} onClose={onClose}>
              <Chakra.ModalOverlay/>
              <Chakra.ModalContent maxWidth={{base: "100%", sm: "90%", md: "80%"}} mx={responsivePixel(10)} display="flex" flexDirection={{base: "column", xl: "row"}}>
                  <Chakra.Image src={image} bg="#21375C" objectFit="cover" height={{xl: "auto"}} p={responsivePixel(30)} maxHeight={{base: "400px", xl: "unset"}} width={{xl: "50%", "2xl": "30%"}} objectPosition={objectPosition} />
                  <Chakra.Box p={responsivePixel(40)} justifyContent="center" display="flex" flexDirection="column">
                      <HeadingText scale={0.7} textAlign="left" mb={responsivePixel(15)}>{title}</HeadingText>
                      <SubtitleText scale={0.7} mb={responsivePixel(25)}>{subtitle}</SubtitleText>
                      <Chakra.ModalCloseButton/>
                      <BodyText scale={0.8} pb={responsivePixel(30)}>
                          {expandedParagraph}
                      </BodyText>
                      <Chakra.Box top="0">
                          <QuotationLeftIcon />
                      </Chakra.Box>
                      <BodyText scale={0.8} pb={responsivePixel(30)}>
                          {summary}
                      </BodyText>
                      <Chakra.Flex width="100%" justifyContent="flex-end">
                          <QuotationRightIcon />
                      </Chakra.Flex>
                      <Chakra.Divider/>
                      <Chakra.Flex pt={responsivePixel(30)} flexDirection="column">
                          <Chakra.Flex borderRadius={{md: "500px 0 14px 500px"}} m="auto" bg={{md: "gray.50"}} d="inline-block" px={responsivePixel(10)} py={responsivePixel(6)}>
                              <Chakra.Stack flexDirection={{base: "column", md: "row"}} alignItems="center" justifyContent="center" spacing={{base: "20px", md: "0"}}>
                                  <DarkButton width="auto" onClick={UIState.onOpenSignupModal}>Enquire</DarkButton>
                              </Chakra.Stack>
                          </Chakra.Flex>
                          <BodyText textAlign="center" pt={responsivePixel(10)} w="100%" scale={0.6}>Or <Chakra.Text as="span" cursor="pointer" onClick={onClose} textDecoration="underline">close this window</Chakra.Text></BodyText>
                      </Chakra.Flex>
                  </Chakra.Box>

              </Chakra.ModalContent>
          </Chakra.Modal>
      </>
  )
}

//as={Link} to={`/search?q=${title}`}
export default Author
