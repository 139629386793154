import * as Chakra from "@chakra-ui/react";
import React from "react";

export function Section ({ bg, children, ...props }: Chakra.BoxProps) {

    return (

        <Chakra.Box bg={bg} w="100%" overflowX="hidden">
            <Chakra.VStack maxWidth={props.maxWidth ? props.maxWidth : "1520px"} maxW={props.maxW ? props.maxW : "1520px"} m="auto" w="100%">
                <Chakra.Box as="section" py={{base: '12', md: '24',}} w="100%">
                    <Chakra.Box mx="auto" px={{base: '6', md: '8',}} css={{".fadeInUp": { animationName: "fadeInUp" },
                        "@keyframes fadeInUp": {
                        "from": {
                                opacity: 0,
                                transform: "translate3d(0, 5%, 0)"
                        },
                        "to": {
                            opacity: 1,
                            transform: "translate3d(0, 0, 0)"
                        }
                        }
                    }} {...props}>
                        {children}
                    </Chakra.Box>
                </Chakra.Box>
            </Chakra.VStack>
        </Chakra.Box>
    )

}
