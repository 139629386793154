import * as Chakra from "@chakra-ui/react";
import {BodyText} from "../text/body-text";
import React from "react";
import {responsivePixel} from "../responsive-px";
import {HeadingText} from "../text/heading-text";
import DarkButton from "../dark-button";
import {UIState} from "../ui-state";
import { SubtitleText } from "../text/subtitle-text";

export interface CourseCardProps {
    image: string,
    title: string,
    summary: any
}

export const CourseCard = ({image, title, subtitle, objectPosition, summary}: CourseCardProps) => {

    const { isOpen, onOpen, onClose } = Chakra.useDisclosure()


    return (

        <Chakra.Box onClick={onOpen} boxShadow="0 15px 45px #756c6c36" cursor="pointer" width="100%" bg="linear-gradient(180deg, #21375C, #21375C00)" borderRadius="25px" position="relative" _hover={{"._in_l": {transform: "scale(1.05)"}, ".in_l_f": { "&:after": { opacity: 1, right: "-15px" }}}}>
            <Chakra.AspectRatio ratio={1}>
                <Chakra.Image className="_in_l" transition="0.4s" opacity={1} position="relative" height="100%" src={image} width="100%" objectFit="cover"/>
            </Chakra.AspectRatio>
                <Chakra.Box maxHeight="min(30vh, 250px)" width="100%" overflow="hidden">

                    <Chakra.Box padding="25px" position="absolute" top="0">
                    <Chakra.Flex display="inline-block" position="relative" alignItems="center" justifyContent="center" ml="10px" className="in_l_f">
                        <BodyText scale={2} as="span" textAlign="left" position="relative" color="#fff">{title}</BodyText>
                    </Chakra.Flex>

                </Chakra.Box>
                </Chakra.Box>
                <Chakra.Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <Chakra.ModalOverlay/>
                    <Chakra.ModalContent maxWidth={{base: "100%", sm: "90%", md: "80%"}} mx={responsivePixel(10)} display="flex" flexDirection={{base: "column", xl: "row"}}>
                        {/*<Chakra.Image src={image} bg="#21375C" objectFit="cover" height={{xl: "auto"}} p={responsivePixel(30)} maxHeight={{base: "400px", xl: "unset"}} width={{xl: "50%", "2xl": "30%"}} objectPosition={objectPosition} />*/}
                        <Chakra.Box p={responsivePixel(40)} justifyContent="center" display="flex" flexDirection="column">
                            <HeadingText scale={0.7} textAlign="left" mb={responsivePixel(15)}>{title}</HeadingText>
                            <SubtitleText scale={0.7} mb={responsivePixel(25)}>{subtitle}</SubtitleText>
                            <Chakra.ModalCloseButton/>
                            <BodyText scale={0.66} pb={responsivePixel(30)}>
                                {summary}
                            </BodyText>
                            <Chakra.Divider/>
                            <Chakra.Flex pt={responsivePixel(30)} flexDirection="column">
                                <Chakra.Flex borderRadius={{md: "500px 0 14px 500px"}} m="auto" bg={{md: "gray.50"}} d="inline-block" px={responsivePixel(10)} py={responsivePixel(6)}>
                                    <Chakra.Stack flexDirection={{base: "column", md: "row"}} alignItems="center" justifyContent="center" spacing={{base: "20px", md: "0"}}>
                                        <DarkButton width="auto" onClick={()=>UIState.addCourseToModal(title)}>Enquire</DarkButton>
                                    </Chakra.Stack>
                                </Chakra.Flex>
                                <BodyText textAlign="center" pt={responsivePixel(10)} w="100%" scale={0.6}>Or <Chakra.Text as="span" cursor="pointer" onClick={onClose} textDecoration="underline">close this window</Chakra.Text></BodyText>
                            </Chakra.Flex>
                        </Chakra.Box>

                    </Chakra.ModalContent>
                </Chakra.Modal>

                    {/*<Chakra.Box>

                    <Chakra.Flex alignItems="center" justifyContent="center" height="100px" width="100px" bg="#21375c" position="absolute" right="20px" marginTop="-50px" borderRadius="50px 0">
                        <Chakra.Box borderRadius="500px" height="60px" width="60px" bg="#315087" padding="15px">
                            <Chakra.Box as={icon} width="100%" height="100%" color="#b8c8e5"/>
                        </Chakra.Box>
                    </Chakra.Flex>
                </Chakra.Box>*/}

        </Chakra.Box>
    )

}
