import * as Chakra from "@chakra-ui/react";
import {responsivePixel} from "../responsive-px";
import DarkButton from "../dark-button";
import React from "react";
import {SubtitleText} from "../text/subtitle-text";
import {UIState} from "../ui-state";

export const InlineCTA = () => {
    return <Chakra.Flex py={responsivePixel(60)}>
        <Chakra.Flex borderRadius="14px" m="auto" d="inline-block"
                     px={responsivePixel(10)} py={responsivePixel(6)}>
            <Chakra.Stack flexDirection={{base: "column", md: "row"}} alignItems="center" justifyContent="center"
                          spacing={{base: "10px", md: "0"}}>
                <DarkButton width="auto" onClick={UIState.onOpenSignupModal}>Talk to us!</DarkButton>
            </Chakra.Stack>
        </Chakra.Flex>
    </Chakra.Flex>;
}
