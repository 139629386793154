import * as React from 'react'
import * as Chakra from '@chakra-ui/react'
import { useKeenSlider } from 'keen-slider/react'
import Author from './author'
import ArrowRightIcon from './arrow-right-icon'
import ArrowLeftIcon from './arrow-left-icon'

interface Props extends Chakra.BoxProps {
  authors: any,
  arrows: boolean
}

const AuthorSlider = ({ authors, arrows }: Props) => {
  const slidesPerView = Chakra.useBreakpointValue({
    base: 1,
    md: 2,
      xl: 3
  })

  const [pause, ] = React.useState<boolean>(false)
  const timer = React.useRef<ReturnType<typeof setInterval>>(null)

  const [positions, setPositions] = React.useState<any>(null);

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: slidesPerView,
    loop: authors.length !== slidesPerView,
    duration: 1000,
    spacing: 30,
    move(s) {
      setPositions(s.details().positions)
    }
  })

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        //slider.next()
      }
    }, 5000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  return (
    <>
      {arrows && (
          <Chakra.Flex d={{ base: 'none', 'lg': 'block' }} position={{base: "relative", lg: "absolute"}} left="-80px" flexDirection="column" zIndex="2">
            <ArrowLeftIcon onClick={() => slider && slider.prev()} cursor="pointer"/>
          </Chakra.Flex>
      )}
      <Chakra.Box w="100%" ref={sliderRef} className="keen-slider" overflow="unset!important">
        {authors.map((author, i) => {
            let op = 0;
            if(positions && positions[i]) {
              op = positions[i]?.portion > 0.5 ? 1 : 0
            }
            return <Author opacity={op} transition="opacity 0.5s" {...author}/>
          })}
      </Chakra.Box>
      {arrows && (
        <Chakra.Flex d={{ base: 'none', 'lg': 'flex' }} ml="40px" flexDirection="column" zIndex="2">
          <ArrowRightIcon onClick={() => slider && slider.next()} marginBottom="16px" cursor="pointer"/>
        </Chakra.Flex>
      )}
    </>
  )
}

export default AuthorSlider
